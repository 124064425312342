import { FC } from 'react'
import Styled from 'styled-components'
import { Link } from 'react-router-dom'
import { CgMouse } from 'react-icons/cg'

import { APP_NAME, FONT_DEFAULT } from '../../constants'
import { BLACK, BLUE, RED, WHITE } from '../../theme'
import { LinkItemProps } from '../../props'
import DoctorImage from '../../../assets/home/doctors.jpg'

type Props = {
  buttons: Array <LinkItemProps>
}

const Section = Styled.section `
  margin-top: clamp(2rem, 2vw, 5rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const Wrapper = Styled.div `
  margin-bottom: 2rem;
  padding: 1rem;
`

const Heading = Styled.div `
  text-align: center;
  font-family: ${ FONT_DEFAULT };
`

const Title = Styled.h1 `
  color: ${ BLUE };
  margin-bottom: 1rem;
  font-size: clamp(1.3rem, 3.5vw, 2rem);
`

const Subtitle = Styled.p `
  color: ${ BLACK };
  font-size: clamp(.8rem, 2vw, 1rem);
  margin-bottom: 2rem;
`

const ButtonGroup = Styled.div `
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 2rem;
  justify-content: center;
  padding: 0 2rem;
`

const Button = Styled (Link) `
  background: transparent;
  border: 1px solid ${ BLUE };
  border-radius: 5px;
  font-family: ${ FONT_DEFAULT };
  padding: 6px 20px;
  font-size: clamp(.6rem, 2vw, .8rem);
  color: ${ BLUE };
  transition: .25s ease-in-out;
  &:hover {
    color: ${ WHITE };
    background: ${ RED };
    border-color: transparent;
  }
`

const Notice = Styled.p `
  color: #888;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 13px;
  font-family: ${ FONT_DEFAULT };
  justify-content: center;
`

const Doctors = Styled.img `
  width: 100%;
  height: auto;
  display: block;
  max-width: 1200px;
`

const HomePageLandingSection: FC <Props> = (props) => {

  return <Section>
    <Wrapper>
      <Heading>
        <Title>Digital Medicine for Filipinos Everywhere</Title>
        <Subtitle>Bringing Digital Transformation to the Filipino People</Subtitle>
      </Heading>
      <ButtonGroup>
        {
          props.buttons.map (
            ({ text, pathname, search, state }, i: number) =>
              <Button 
                to = {{
                pathname,
                search,
                state
                }}
                key = { i }>{ text }</Button>
          )
        }
      </ButtonGroup>
      <Notice>
        <CgMouse
          size = { 20 }
        />
        Scroll down
      </Notice>
    </Wrapper>
    <Doctors
      src = { DoctorImage }
      alt = { APP_NAME }
    />
  </Section>

}

export default HomePageLandingSection
