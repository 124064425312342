import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import Styled from 'styled-components'
import { v4 } from 'uuid'
import { Formik, Form as FormikForm, Field as FormikField } from 'formik'
import * as Yup from 'yup'

import { FONT_DEFAULT, PATHNAME_SIGN_IN } from '../../constants'
import { BLUE, RED, WHITE, YELLOW } from '../../theme'

type Props = {}


const Form = Styled (FormikForm) `
  padding: 2.5rem 1rem 0 2rem;
  max-width: 640px;
  width: 100%;
  margin: 0 auto;
  overflow-y: auto;
`

const SignInHeading = Styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: ${ FONT_DEFAULT };
  gap: 1rem;

  @media (min-width: 580px) {
    align-items center;
    flex-direction: row-reverse !important;
    justify-content: flex-start !important;
    flex-wrap: wrap;
  }
`

const SignIn = Styled (Link) `
  border: 1px solid ${ BLUE };
  padding: clamp(.3rem, 2vw, .4rem) clamp(1rem, 2vw, 2rem);
  font-size: clamp(.7rem, 2vw, .8rem);
  border-radius: 8px;
  transition: .25s ease-in-out;
  font-weight: 600;
  color: ${ BLUE };
  &:hover {
    border-color: ${ RED };
    background: ${ RED };
    color: ${ WHITE };
  }
`

const HaveAccount = Styled.p `
  font-size: clamp(.7rem, 2vw, .8rem);
  color: #888;
  letter-spacing: 1px;
`

const WelcomeHeading = Styled.div `
  margin-top: 4rem;
`

const Welcome = Styled.h1 `
  display: flex;
  gap: .5rem;
  font-family: ${ FONT_DEFAULT };
  font-weight: 500;
  font-size: clamp(1rem, 2.7vw, 1.7rem);
`

const WelcomeText = Styled.span `
  font-family: inherit;
`

const WelcomeTextA = Styled (WelcomeText) `
  color: ${ YELLOW };
`
const WelcomeTextB = Styled (WelcomeText) `
  color: ${ BLUE };
`
const WelcomeTextC = Styled (WelcomeText) `
  color: ${ RED };
`

const Notice = Styled.p `
  color: #888;
  font-size: clamp(.85rem, 2vw, 1rem);
  font-family: ${ FONT_DEFAULT };
`

const ControlContainer = Styled.div `
  margin: 4rem 1rem 2rem auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
  gap: 0 1rem;

  @media (min-width: 1200px) {
    gap: 0 2rem;
  }
`

const ControlWrapper = Styled.div `
  margin-bottom: 2.5rem;
  position: relative;
`

const Input = Styled (FormikField) `
  padding: clamp(.5rem, 2vw, .7rem) clamp(.7rem, 2vw, 1rem);
  width: 100%;
  font-family: ${ FONT_DEFAULT };
  font-size: clamp(.75rem, 2vw, .85rem);
  margin-top: 5px;
  border-radius: 8px;
  border: 1px solid #888;
`

const Label = Styled.label `
  font-size: clamp(.75rem, 2vw, .85rem);
  font-family: ${ FONT_DEFAULT };
`

const Submit = Styled.button `
  display: block;
  width: 100%;
  padding: clamp(.7rem, 2vw, 1rem);
  font-family: ${ FONT_DEFAULT };
  background: ${ BLUE };
  border: none;
  border-radius: 8px;
  color: ${ WHITE };
  cursor: pointer;
`

const Footer = Styled.div `
  display: flex;
  justify-content: center;
  gap: 2rem;
  align-items: center;
  margin-right: 1rem;
`

const FooterLink = Styled (Link) `
  font-size: clamp(.7rem, 2vw, .8rem);
  font-family: ${ FONT_DEFAULT };
  color: #888;
`

const Error = Styled.span `
  padding: .3rem 1rem;
  font-size: clamp(.5rem, 2vw, .75rem);
  position: absolute;
  bottom: -30px;
  right: 0;
  color: #fff;
  &::before {
    content: '';
    background: rgba(139,17,17,.7);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 20px;
  }
`

const PrivacyPolicy = Styled (FooterLink) ``

const RegisterForm: FC <Props> = (props) => {

  const username_id: string = v4 ()
  const password_id: string = v4 ()
  const email_id: string = v4 ()
  const password2_id: string = v4 ()
  const contact_number_id: string = v4 ()
  const address_id: string = v4 ()

  const validation = Yup.object ().shape ({
    username: Yup.string ()
      .min (4, 'Too short')
      .required ('Enter your username'),
    email: Yup.string ()
      .email ('Invalid email format')
      .required ('Enter your email address'),
    password: Yup.string ()
      .min (8, 'Too short')
      .required ('Enter your password'),
    password2: Yup.string ()
      .min (8, 'Too short')
      .oneOf ([Yup.ref ('password'), null], 'Passwords does not matched.')
      .required ('Enter your password'),
    contact_number: Yup.string ()
      .required ('Enter your contact number')
      .matches (/^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/, 'Unsupported contact number'),
    address: Yup.string ()
      .min (8, 'Too short')
      .required ('Enter your address')
      
  })

  return <Formik
    onSubmit = {
      (values) => {}
    }
    validationSchema = { validation }
    initialValues = {{
      username: '',
      password: '',
      password2: '',
      contact_number: '',
      address: '',
      email: ''
    }}>
    {
      ({ errors, touched }) => <Form>
        <SignInHeading>
          <SignIn to = { PATHNAME_SIGN_IN }>Sign In</SignIn>
          <HaveAccount>Already have an account?</HaveAccount>
        </SignInHeading>
        <WelcomeHeading>
          <Welcome>
            <WelcomeTextA>Welcome to</WelcomeTextA>
            <WelcomeTextB>Kabayan</WelcomeTextB>
            <WelcomeTextC>Doctors</WelcomeTextC>
          </Welcome>
          <Notice>Sign up now for free</Notice>
        </WelcomeHeading>
        <ControlContainer>
          <ControlWrapper>
            <Label htmlFor = { username_id }>
              Username
              <Input
                placeholder = 'Your username'
                name = 'username'
              />
            </Label>
            { 
              errors.username && touched.username &&
              <Error>{ errors.username }</Error>
            }
          </ControlWrapper>
          <ControlWrapper>
            <Label htmlFor = { email_id }>
              Email Address
              <Input
                placeholder = 'example@domain.com'
                name = 'email'
                type = 'email'
              />
            </Label>
            { 
              errors.email && touched.email &&
              <Error>{ errors.email }</Error>
            }
          </ControlWrapper>
          <ControlWrapper>
            <Label htmlFor = { contact_number_id }>
              Contact Number
              <Input
                placeholder = '+63'
                name = 'contact_number'
              />
            </Label>
            { 
              errors.contact_number && touched.contact_number &&
              <Error>{ errors.contact_number }</Error>
            }
          </ControlWrapper>
          <ControlWrapper>
            <Label htmlFor = { address_id }>
              Address
              <Input
                placeholder = '123 City'
                name = 'address'
              />
            </Label>
            { 
              errors.address && touched.address &&
              <Error>{ errors.address }</Error>
            }
          </ControlWrapper>
          <ControlWrapper>
            <Label htmlFor = { password_id }>
              Password
              <Input
                placeholder = '8+ characters'
                type = 'password'
                name = 'password'
              />
            </Label>
            { 
              errors.password && touched.password &&
              <Error>{ errors.password }</Error>
            }
          </ControlWrapper>
          <ControlWrapper>
            <Label htmlFor = { password2_id }>
              Confirm Password
              <Input
                placeholder = '8+ characters'
                type = 'password'
                name = 'password2'
              />
            </Label>
            { 
              errors.password2 && touched.password2 &&
              <Error>{ errors.password2 }</Error>
            }
          </ControlWrapper>
          <Submit type = 'submit'>Create</Submit>
        </ControlContainer>
        <Footer>
          <PrivacyPolicy to = '/'>Privacy Policy</PrivacyPolicy>
        </Footer>
      </Form>
    }
  </Formik>

}

export default RegisterForm