import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import Styled from 'styled-components'

import { FONT_DEFAULT } from '../../constants'
import { ListItemProps } from '../../props'
import { BLACK, BLUE, WHITE, YELLOW } from '../../theme'

type Props = {
  items: Array <ListItemProps>
}

const Section = Styled.section `
  margin-bottom: 5rem;
`

const Heading = Styled.div `
  font-family: ${ FONT_DEFAULT };
  margin-bottom: 5rem;
  position: relative;
  text-align: center;
  &::before {
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: ${ YELLOW };
    z-index: -1;
  }
`

const HeadingTitle = Styled.h2 `
  text-transform: uppercase;
  color: ${ BLUE };
  padding: 0 10px;
  display: inline;
  background: #fff;
  font-size: clamp(1rem, 2vw, 1.7rem);
`

const List = Styled.ul `
  margin: 0 auto;
  max-width: 768px;
  font-family: ${ FONT_DEFAULT };
  padding: 0 1rem;
`

const ListItem = Styled.li `
  display: flex;
  flex-wrap: wrap;
  justify-content: center; 
  gap: 1rem 0;
  position: relative;
  border-radius: 5px;
  &.emr {

  }
  &:nth-of-type(even) {
    flex-direction: row-reverse;
    transform: translateX(-1px);
    .content {
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
    }
  }
  &:nth-of-type(odd) {
    .content {
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
    }
  }
  &:not(:last-of-type) {
    margin-bottom: 4rem;
  }
  @media (min-width: 752px) {
    &::before {
      content: '';
      position: absolute;
      left: 10px;
      top: 0;
      right: 8px;
      bottom: 0;
      border-radius: inherit;
    }
    &:nth-of-type(1) {
      &::before { box-shadow: 0 3px 10px rgba(246, 76, 0, .6); }
    }
    &:nth-of-type(2) {
      &::before { box-shadow: 0 3px 10px rgba(12, 175, 187, .6); }
    }
    &:nth-of-type(3) {
      &::before { box-shadow: 0 3px 10px rgb(185, 229, 159, .6); }
    }
    &:nth-of-type(odd) {
      .content {
        text-align: left;
        a {
          margin: 0 auto 0 0;
        }
      }
    }
    &:nth-of-type(even) {
      .content {
        text-align: right;
        a {
          margin: 0 0 0 auto;
        }
      }
    }
  }
`

const ListImage = Styled.img `
  max-width: 360px;
  width: 80%;
  height: auto;
  border-radius: 8px;
  @media (min-width: 752px) {
    border-radius: unset;
    max-width: 375px;
  }
  &.emr {
    padding: 1rem;
  }
`

const ListContent = Styled.div <{ base_color: string }> `
  max-width: 360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  text-align: center;
  padding: 1rem;
  justify-content: space-between;

  a {
    border: 1px solid ${ props => props.base_color };
    color: ${ props => props.base_color };
    &:hover {
      background: ${ props => props.base_color };
      color: ${ WHITE };
    }
  }

  @media (min-width: 752px) {
    padding: 1rem 1.5rem;
  }
`

const ListButton = Styled (Link) `
  position: relative;
  padding: .5rem 1.5rem;
  font-size: clamp(.7rem, 2vw, .8rem);
  margin: 0 auto;
  border-radius: 5px;
  transition: .25s ease-in-out;
  z-index: 10;
`

const ListTitle = Styled.h3 <{ base_color: string }> `
  font-size: clamp(1.1rem, 2vw, 1.2rem);
  text-transform: uppercase;
  color: ${ props => props.base_color };
`

const ListDescription = Styled.p `
  font-size: clamp(.85rem, 1.5vw, .95rem);
  color: ${ BLACK };
  line-height: 1.8;
  font-weight: 300;
`

const ListContentWrapper = Styled.div `
  display: flex;
  flex-direction: column;
  gap: .75rem;
`

const HomePageServicesSection: FC <Props> = (props) => {

  return <Section>
    <Heading>
      <HeadingTitle>Our Services</HeadingTitle>
    </Heading>
    <List>
      {
        props.items.map (
          ({ image, title, description, color, link, className }: ListItemProps, i: number) =>
            <ListItem key = { i }>
              <ListImage
                { ...image }
                className = { className }
              />
              {
                color && <ListContent base_color = { color.base } className = 'content'>
                  <ListContentWrapper>
                    <ListTitle
                        base_color = { color.base }>{ title }</ListTitle>
                    <ListDescription>{ description }</ListDescription>
                    {
                      link && <ListButton
                        rel = 'noreferrer noopener'
                        target = '_blank'
                        to = {{
                          pathname: link.pathname
                        }}>Visit Website</ListButton>
                    }
                  </ListContentWrapper>
                </ListContent>
              }
            </ListItem>
        )
      }
    </List>
  </Section>

}

export default HomePageServicesSection