import React, { FC, InputHTMLAttributes, TextareaHTMLAttributes } from 'react'
import Styled from 'styled-components'
import { Formik, Form as FormikForm, Field as FormikField } from 'formik'
import * as Yup from 'yup'

import BackgroundImage from '../../../assets/contact_us/bg.jpg'
import { FONT_DEFAULT } from '../../constants'
import { TextFieldProps } from '../../props'
import { BLUE, WHITE } from '../../theme'
import { form } from './data'


type Props = {}

const Form = Styled (FormikForm) `
  position: relative;
  padding: 1.5rem;
  font-family: ${ FONT_DEFAULT };
  flex-wrap: wrap;
  gap: 1.5rem 1rem;
  color: ${ WHITE };

  display: grid;
  grid-template-columns: 1fr;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &::before {
    background: url(${ BackgroundImage }) no-repeat;
    background-size: cover;
    background-position: center left;
    z-index: -2;
    filter: blur(4px);
  }
  &::after {
    background: rgba(0, 0, 0, 0.7);
    z-index: -1;
  }

  @media (min-width: 360px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'heading heading'
      'full_name phone'
      'email subject'
      'message message'
      'submit .'
    ;
  }

  @media (min-width: 1200px) {
    padding: 2rem 3rem;
  }
`

const Heading = Styled.div `
  margin-bottom: 2rem;
  @media (min-width: 360px) {
    grid-area: heading;
  }
`

const Title = Styled.h3 `
  font-weight: 500;
  font-size: clamp(1rem, 2vw, 1.2rem);
`

const TextFieldContainer = Styled.div <{ multiline?: boolean }> `
  width: 100%;
  position: relative;
  &:nth-of-type(2) { grid-area: full_name; }
  &:nth-of-type(3) { grid-area: phone; }
  &:nth-of-type(4) { grid-area: email; }
  &:nth-of-type(5) { grid-area: subject; }
  &:nth-of-type(6) { grid-area: message; }
`

const Label = Styled.label `
  display: flex;
  flex-direction: column;
  font-size: clamp(.7rem, 2vw, .8rem);

  input,
  textarea {
    width: 100%;
    padding: .5rem 1rem;
    font-family: ${ FONT_DEFAULT };
    font-size: clamp(.7rem, 2vw, .74rem);
    margin-top: .5rem;
    font-weight: 400;
    letter-spacing: .5px;
    background: transparent;
    color: #fff;
  }
`

const TextInput = Styled (FormikField) `
  border: none;
  border-bottom: 1px solid #fff;
  width: 100%;
`

const TextArea = Styled (FormikField) `
  resize: none;
  border: 1px solid #fff;
`

const Button = Styled.button `
  background: ${ BLUE };
  border: none;
  border-radius: 5px;
  padding: .8rem 3rem;
  font-family: ${ FONT_DEFAULT };
  color: ${ WHITE };
  cursor: pointer;
  letter-spacing: 1px;
  
  @media (min-width: 360px) {
    grid-area: submit;
  }
`

const Error = Styled.span `
  padding: .3rem 1rem;
  font-size: clamp(.5rem, 2vw, .6rem);
  position: absolute;
  bottom: -25px;
  right: 0;
  color: #fff;
  z-index: 3;
  &::before {
    content: '';
    background: rgba(139,17,17,.4);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    border-radius: 20px;
  }
`

const ContactUsPageForm: FC <Props> = (props) => {

  const validation = Yup.object ().shape ({
    full_name: Yup.string ()
      .min (4, 'Too short')
      .required ('Enter your name'),
    contact_number: Yup.string ()
      .required ('Enter your contact number')
      .matches (/^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/, 'Unsupported contact number'),
    email: Yup.string ()
      .email ('Invalid email format')
      .required ('Enter your email address'),
    subject: Yup.string ()
      .min (1, 'Too short')
      .required ('Enter the subject'),
    message: Yup.string ()
      .min (10, 'Too short')
      .required ('Enter your message'),
  })

  const createError = (name: string | undefined, errors: any, touched: any): JSX.Element | null => {
    if (name && errors[name] && touched[name])
      return <Error>{ errors[name] }</Error>
    return null
  }

  return <Formik
    onSubmit = {
      (values) => {}
    }
    initialValues = {{
      full_name: '',
      contact_number: '',
      email: '',
      subject: '',
      message: ''
    }}
    validationSchema = { validation }
  >
    {
      ({ errors, touched }) => <Form>
        <Heading>
          <Title>Send your request</Title>
        </Heading>
        {
          form.fields.map (
            ({ attr, label, multiline }: TextFieldProps, i: number) =>
              <TextFieldContainer
                multiline = { multiline }
                key = { i }
              >
                <Label htmlFor = { label.id }>
                  { label.value }
                  {
                    multiline ?
                      <TextArea 
                        { ...attr as TextareaHTMLAttributes <HTMLTextAreaElement> }
                        id = { label.id }
                        as = 'textarea'
                      /> 
                    :
                      <TextInput 
                        { ...attr as InputHTMLAttributes <HTMLInputElement> }
                        id = { label.id } 
                      />
                  }
                </Label>
                {
                  createError (attr.name, errors, touched)
                }
              </TextFieldContainer>
          )
        }
        <Button { ...form.submit.attr }>
          { form.submit.text }
        </Button>
      </Form>
    }
  </Formik>

}

export default ContactUsPageForm