import { v4 } from 'uuid'
import { FormProps } from '../../props'

export const form: FormProps = {
  fields: [
    {
      attr: {
        placeholder: 'John Doe',
        name: 'full_name'
      },
      label: {
        id: v4 (),
        value: 'Name'
      }
    },
    {
      attr: {
        placeholder: '+639123456789',
        name: 'contact_number'
      },
      label: {
        id: v4 (),
        value: 'Phone'
      }
    },
    {
      attr: {
        placeholder: 'example@domain.com',
        name: 'email'
      },
      label: {
        id: v4 (),
        value: 'Email'
      }
    },
    {
      attr: {
        placeholder: 'EMR',
        name: 'subject'
      },
      label: {
        id: v4 (),
        value: 'Subject'
      }
    },
    {
      multiline: true,
      attr: {
        rows: 8,
        name: 'message'
      },
      label: {
        id: v4 (),
        value: 'Message'
      }
    }
  ],
  submit: {
    text: 'Submit'
  }
}