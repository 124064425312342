import React, { FC } from 'react'
import Styled from 'styled-components'
import { RiPhoneLine, RiMapPinLine, RiMailLine } from 'react-icons/ri'
import { COMPANY_ADDRESS, COMPANY_CONTACT_NUMBERS, COMPANY_EMAIL_ADDRESS, FONT_DEFAULT } from '../../constants'
import { BLUE, WHITE } from '../../theme'
import { IconContext } from 'react-icons/lib'

type Props = {}

const Container = Styled.div `
  display: none;
  background: ${ BLUE };
  color: ${ WHITE };
  padding: 3rem;

  @media (min-width: 768px) {
    display: initial;
  }
`

const Heading = Styled.div `
  margin-bottom: 4rem;
`

const Title = Styled.h3 `
  font-family: ${ FONT_DEFAULT };
  font-weight: 500;
`

const List = Styled.ul ``

const ListItem = Styled.li `
  align-items: center;
  display: flex;
  gap: 1rem;
  &:not(:last-of-type) {
    margin-bottom: 3rem;
  }
`

const ListText = Styled.p `
  font-size: clamp(.7rem, 2vw, .8rem);
  line-height: 2.3;
  font-family: ${ FONT_DEFAULT };
`

const ContactUsPageAddress: FC <Props> = (props) => {

  return <Container>
    <Heading>
      <Title>Reach Us</Title>
    </Heading>
    <IconContext.Provider value = {{
      style: {
        minWidth: 50
      }
    }}>
      <List>
        <ListItem>
          <RiMapPinLine
            size = { 30 }
          />
          <ListText>{ COMPANY_ADDRESS }</ListText>
        </ListItem>
        <ListItem>
          <RiPhoneLine
            size = { 28 }
          />
          <ListText>{ COMPANY_CONTACT_NUMBERS.join (' / ') }</ListText>
        </ListItem>
        <ListItem>
          <RiMailLine
            size = { 26 }
          />
          <ListText>{ COMPANY_EMAIL_ADDRESS }</ListText>
        </ListItem>
      </List>
    </IconContext.Provider>
  </Container>

}

export default ContactUsPageAddress