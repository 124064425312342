export const APP_NAME: string = 'Kabayan Doctors'
export const COMPANY_ADDRESS: string = 'Kabayan Doctors, 20/F, Zueling Building Makati Ave. Corner Paseo de Roxas Makati City, Philippines'
export const COMPANY_CONTACT_NUMBERS: Array <string> = [ '+632-8465-9200', '+63939-931-0238' ]
export const COMPANY_EMAIL_ADDRESS: string = 'info@pythonph.com'
export const FONT_DEFAULT: string = 'Poppins, sans-serif'

export const PATHNAME_HOME: string = '/'
export const PATHNAME_SERVICES: string = '/services'
export const PATHNAME_ABOUT_US: string = '/about-us'
export const PATHNAME_CONTACT_US: string = '/contact-us'
export const PATHNAME_SIGN_IN: string = '/sign-in'
export const PATHNAME_SIGN_UP: string = '/sign-up'

export const NAVIGATION_INITIAL_HEIGHT: string = '109px';