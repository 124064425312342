import React, { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

import FooterNavigation from '../../component/FooterNavigation'
import { ABOUT_US_DESCRIPTION_LIST_ITEMS, ABOUT_US_DOCTOR_IMAGE_SRC, LANDING_BUTTONS, LANDING_QUICK_LINKS, ABOUT_US_LIST_ITEMS, SERVICES_LIST_ITEMS } from './data'
import HomePageAboutUsSection from './about_us'
import HomePageLandingSection from './landing'
import HomePageQuickLinksSection from './quick_links'
import HomePageServicesSection from './services'
import MainContainer from '../main_container'
import { APP_NAME } from '../../constants'
import HomePageOurApplicationSection from './qr'

type Props = {}

const HomePage: FC <Props> = (props) => {

  useEffect (() => {
    window.scrollTo(0, 0)
  }, [])

  return <MainContainer>
    <Helmet
      title = { APP_NAME.concat (' - Home') }
    />
    <HomePageQuickLinksSection
      buttons = { LANDING_QUICK_LINKS }
    />
    <HomePageLandingSection
      buttons = { LANDING_BUTTONS }
    />
    <HomePageAboutUsSection
      descriptions = { ABOUT_US_DESCRIPTION_LIST_ITEMS }
      doctor_image_src = { ABOUT_US_DOCTOR_IMAGE_SRC }
      items = { ABOUT_US_LIST_ITEMS }
    />
    <HomePageServicesSection
      items = { SERVICES_LIST_ITEMS }
    />
    <HomePageOurApplicationSection />
    <FooterNavigation />
  </MainContainer>

}

export default HomePage
