import React, { FC } from 'react'
import Styled from 'styled-components'
import QRCode from 'qrcode.react'
import { Link } from 'react-router-dom'

import { BLACK, BLUE } from '../../theme'
import { APP_NAME, FONT_DEFAULT } from '../../constants'
import Apple from '../../../assets/home/apple_store.png'
import GooglePlay from '../../../assets/home/google_play_store.png'
import Background from '../../../assets/home/app_bg.jpg'
import { ListItemProps } from '../../props'

type Props = {}

const Section = Styled.section `
  background: url(${ Background }) no-repeat;
  background-position: top left;
  background-size: cover;
`

const Grid = Styled.div `
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: .6fr 1fr .3fr;
  gap: 1rem;
  grid-template-areas:
    'title title'
    'qr store'
    '. .'
  ;
  padding: 1rem;
  @media (min-width: 440px) {
    grid-template-areas:
      '. title'
      'qr store'
      '. .'
    ;
  }

  @media (min-width: 838px) {
    grid-template-columns: .5fr 1fr;
    grid-template-areas:
      'qr title'
      'qr desc'
      'qr store'
    ;
    padding-left: 0;
    grid-template-rows: repeat(3, 1fr);
  }
`

const Title = Styled.h2 `
  grid-area: title;
  font-family: ${ FONT_DEFAULT };
  font-size: clamp(.9rem, 2.5vw, 1.5rem);
  text-transform: uppercase;
  margin: auto;
  color: ${ BLUE };

  @media (min-width: 360px and max-width: 440px) {
    margin: auto 0 auto auto;
  }

  @media (min-width: 838px) {
    margin: auto 0 0 0;
  }
`

const StoreList = Styled.ul `
  grid-area: store;
  padding: 1rem 2rem;
  margin: auto;
  display: flex;
  gap: 1.5rem;
  flex-direction: column;

  @media (min-width: 838px) {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 0 auto 0;
    padding: 0;
  }
`

const StoreItem = Styled.li ``

const StoreLink = Styled (Link) `
  display: flex;
  align-items: center;
  gap: .5rem;
  font-family: ${ FONT_DEFAULT };
  font-size: clamp(.7rem, 1.5vw, .95rem);
  color: ${ BLACK };
  text-align: center;
`

const StoreImage = Styled.img `
  width: 24px;
  height: auto;
`

const QrContainer = Styled.div `
  grid-area: qr;
  margin: auto;
  height: 100%;

  @media (min-width: 838px) {
    display: flex;
    margin: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    &::before {
      content: '';
    }
  }
`

const QRBorder = Styled.div `
  position: relative;
  z-index: 3;
  background:
    linear-gradient(to right, #fff 3px, transparent 3px) 0 0,
    linear-gradient(to right, #fff 3px, transparent 3px) 0 100%,
    linear-gradient(to left, #fff 3px, transparent 3px) 100% 0,
    linear-gradient(to left, #fff 3px, transparent 3px) 100% 100%,
    linear-gradient(to bottom, #fff 3px, transparent 3px) 0 0,
    linear-gradient(to bottom, #fff 3px, transparent 3px) 100% 0,
    linear-gradient(to top, #fff 3px, transparent 3px) 0 100%,
    linear-gradient(to top, #fff 3px, transparent 3px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 10px 10px;
  width: 106px;
  height: 106px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 86px !important;
  }

  @media (min-width: 460px) {
    background:
      linear-gradient(to right, #fff 5px, transparent 5px) 0 0,
      linear-gradient(to right, #fff 5px, transparent 5px) 0 100%,
      linear-gradient(to left, #fff 5px, transparent 5px) 100% 0,
      linear-gradient(to left, #fff 5px, transparent 5px) 100% 100%,
      linear-gradient(to bottom, #fff 5px, transparent 5px) 0 0,
      linear-gradient(to bottom, #fff 5px, transparent 5px) 100% 0,
      linear-gradient(to top, #fff 5px, transparent 5px) 0 100%,
      linear-gradient(to top, #fff 5px, transparent 5px) 100% 100%;
    width: 172px;
    height: 172px;
    background-size: 30px 30px;
    background-repeat: no-repeat;
    
    svg {
      width: 164px !important;
    }
  }
`

const Subtitle = Styled.p `
  display: none;
  grid-area: desc;
  font-family: ${ FONT_DEFAULT };
  font-size: clamp(.85rem,1.5vw,.95rem);
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 2.3;
  padding-right: 1rem;

  @media (min-width: 838px) {
    display: initial;
  }
`

const StoreTitle = Styled.li `
  display: none;
  font-family: ${ FONT_DEFAULT };
  font-size: clamp(.9rem, 2.5vw, 1rem);
  text-transform: uppercase;
  width: 100%;
  color: ${ BLUE };

  @media (min-width: 838px) {
    display: initial;
  }
`

const HomePageOurApplicationSection: FC <Props> = (props) => {


  const stores: Array <ListItemProps> = [
    {
      title: 'Play Store',
      image: {
        src: GooglePlay,
        alt: 'google-play-store'
      },
      description: ''
    },
    {
      title: 'Apple Store',
      image: {
        src: Apple,
        alt: 'apple-store'
      },
      description: ''
    }
  ]

  return <Section>
    <Grid>
      <QrContainer>
        <QRBorder>
          <QRCode
            value = { APP_NAME }
            size = { 128 }
            renderAs = 'svg'
          />
        </QRBorder>
      </QrContainer>
      <Title>{ APP_NAME } App</Title>
      <Subtitle>Our doctors are available 24/7 to give patients who seek medical advise the much needed help through video chat wherever there is internet connection. Patients can use their desktop computers, tablets or smartphones to talk to a doctor face to face.</Subtitle>
      <StoreList>
        <StoreTitle>Available now at:</StoreTitle>
        {
          stores.map (
            ({ title, image }: ListItemProps, i: number) =>
              <StoreItem key = { i }>
                <StoreLink to = '/'>
                  <StoreImage { ...image } />
                  { title }
                </StoreLink>
            </StoreItem>
          )
        }
      </StoreList>
    </Grid>
  </Section>

}

export default HomePageOurApplicationSection