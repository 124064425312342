import React, { FC } from 'react'
import Styled from 'styled-components'
import { BiChevronRight } from 'react-icons/bi'
import { RiPhoneLine, RiMapPinLine } from 'react-icons/ri'
import { IconContext } from 'react-icons'
import { Link } from 'react-router-dom'

import {
  APP_NAME,
  COMPANY_ADDRESS,
  COMPANY_CONTACT_NUMBERS,
  FONT_DEFAULT,
  PATHNAME_ABOUT_US,
  PATHNAME_CONTACT_US,
  PATHNAME_HOME } from '../constants'
import { BLACK, BLUE } from '../theme'
import { LinkItemProps, ListItemProps } from '../props'

import Facebook from '../../assets/fb.svg'
import Twitter from '../../assets/twitter.svg'
import GooglePlus from '../../assets/g+.svg'
import LinkedIn from '../../assets/linkedin.svg'
import Skype from '../../assets/skype.svg'
import Youtube from '../../assets/yt.svg'

type Props = {}

const Footer = Styled.footer `
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`

const Navigation = Styled.div `
  padding: 2rem;
  font-family: ${ FONT_DEFAULT };
  display: inline-block;

  @media (min-width: 640px) {
    width: 50%;
  }
`

const NavigationTitle = Styled.h3 `
  color: ${ BLUE };
  font-weight: 500;
  border-bottom: 1px solid #888;
  padding-bottom: 1rem;
  font-size: clamp(.8rem, 2vw, 1.1rem);
`

const NavigationList = Styled.ul `
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  gap: 1.5rem .5rem;
  max-width: 320px;

  @media (min-width: 992px) { max-width: 380px; }
`

const NavigationListItem = Styled.li `
  font-size: clamp(.65rem, 2vw, .8rem);
  color: ${ BLACK };
  display: flex;
`

const NavigationListItemA = Styled (NavigationListItem) `
  width: 130px;
`

const NavigationListLink = Styled (Link) `
  color: ${ BLACK };
  display: flex;
  font-size: inherit;
  align-items: center;
`

const NavigationListItemB = Styled (NavigationListItem) `
  gap: 10px;
  max-width: 500px;
  width: 100% !important;

  .icon {
    max-width: 30px;
    width: 100%;
  }
`

const SocialMediaList = Styled.ul `
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  margin-left: 3rem;
`

const SocialMediaListItem = Styled.li ``

const SocialMediaLink = Styled (Link) ``

const SocialMediaImage = Styled.img `
  max-width: 24px;
  width: 100%;
  height: auto;
`

const Copyrights = Styled.p `
  color: #777;
  font-size: clamp(.65rem, 2vw, .8rem);
  text-align: center;
  padding: 0 2rem;
  margin: 0 auto;
`

const FooterNavigation: FC <Props> = (props) => {

  const navigations: Array <LinkItemProps> = [
    {
      text: 'Home',
      pathname: PATHNAME_HOME
    },
    {
      text: 'About Us',
      pathname: PATHNAME_ABOUT_US
    },
    {
      text: 'Services',
      pathname: PATHNAME_HOME
    },
    {
      text: 'Contact Us',
      pathname: PATHNAME_CONTACT_US
    }
  ]

  const company_infos: Array <ListItemProps> = [
    {
      description: COMPANY_ADDRESS,
      title: 'complete-address',
      icon: {
        jsx: <RiMapPinLine />,
        size: 20
      }
    },
    {
      description: COMPANY_CONTACT_NUMBERS.join (' / '),
      title: 'phone-numbers',
      icon: {
        jsx: <RiPhoneLine />,
        size: 20
      }
    }
  ]

  const social_medias: Array <ListItemProps> = [
    {
      description: '',
      title: 'Facebook',
      image: { src: Facebook },
      link: {
        pathname: '/'
      }
    },
    {
      description: '',
      title: 'Twitter',
      image: { src: Twitter },
      link: {
        pathname: '/'
      }
    },
    {
      description: '',
      title: 'Google+',
      image: { src: GooglePlus },
      link: {
        pathname: '/'
      }
    },
    {
      description: '',
      title: 'LinkedIn',
      image: { src: LinkedIn },
      link: {
        pathname: '/'
      }
    },
    {
      description: '',
      title: 'Skype',
      image: { src: Skype },
      link: {
        pathname: '/'
      }
    },
    {
      description: '',
      title: 'Youtube',
      image: { src: Youtube },
      link: {
        pathname: '/'
      }
    }
  ]

  return <Footer>
    <Navigation>
      <NavigationTitle>{ APP_NAME }</NavigationTitle>
      <NavigationList>
        {
          navigations.map (
            ({ text, pathname, state, search }: LinkItemProps, i: number) =>
              <NavigationListItemA key = { i }>
                <NavigationListLink to = {{
                  pathname, search, state
                }}>
                  { text }
                  <BiChevronRight />
                </NavigationListLink>
              </NavigationListItemA>
          )
        }
      </NavigationList>
    </Navigation>
    <Navigation>
      <NavigationTitle>Get in touch</NavigationTitle>
      <NavigationList>
        {
          company_infos.map (
            ({ description, icon }: ListItemProps, i: number) =>
              <NavigationListItemB key = { i }>
                {
                  icon && <IconContext.Provider value = {{
                    size: `${ icon.size || 20 }px`,
                    color: icon.color || BLACK,
                    className: 'icon'
                  }}>
                    { icon.jsx }
                  </IconContext.Provider>
                }
                { description }
              </NavigationListItemB>
          )
        }
      </NavigationList>
      <SocialMediaList>
        {
          social_medias.map (
            ({ image, title, link }: ListItemProps, i: number) =>
              <SocialMediaListItem key = { i }>
                {
                  link && <SocialMediaLink to = { link }>
                    {
                      image && <SocialMediaImage
                        src = { image.src }
                      />
                    }
                  </SocialMediaLink>
                }
              </SocialMediaListItem>
          )
        }
      </SocialMediaList>
    </Navigation>
    <Copyrights>Copyright &copy; 2021 { APP_NAME }. All right reserved.</Copyrights>
  </Footer>

}

export default FooterNavigation