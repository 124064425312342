import React, { FC } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import HeadNavigation from './component/HeadNavigation'
import { APP_NAME, PATHNAME_ABOUT_US, PATHNAME_CONTACT_US, PATHNAME_HOME, PATHNAME_SERVICES, PATHNAME_SIGN_IN, PATHNAME_SIGN_UP } from './constants'
import LOGO from '../assets/logo.svg'
import HomePage from './pages/home'
import ContactUsPage from './pages/contact_us'
import AboutUsPage from './pages/about_us'
import LoginPage from './pages/login'
import RegisterPage from './pages/register'

type Props = {}

const App: FC <Props> = (props) => {
  
  const Navigation = () => <HeadNavigation
    app_name = { APP_NAME }
    logo = {{
      image: LOGO,
      link: {
        pathname: PATHNAME_HOME
      }
    }}
    menus = {[
      { pathname: PATHNAME_HOME, text: 'Home' },
      { 
        pathname: PATHNAME_SERVICES,
        text: 'Services',
        sub_menus: [
          {
            pathname: '/',
            text: 'Telemedecine'
          },
          {
            pathname: '/',
            text: 'Scheduling'
          },
          {
            pathname: '/',
            text: 'Electronic Medical Record'
          }
        ]
      },
      { pathname: PATHNAME_ABOUT_US, text: 'About Us' },
      { pathname: PATHNAME_CONTACT_US, text: 'Contact Us' },
      { pathname: PATHNAME_SIGN_IN, text: 'Sign In'  }
    ]}
  />

  return <Router>
    <Switch>
      <Route
        exact
        path = { PATHNAME_HOME }>
        <Navigation />
        <HomePage />
      </Route>
      <Route
        path = { PATHNAME_CONTACT_US }>
        <Navigation />
        <ContactUsPage />
      </Route>
      <Route
        path = { PATHNAME_ABOUT_US }>
        <Navigation />
        <AboutUsPage />
      </Route>
      <Route
        path = { PATHNAME_SIGN_IN }
        component = { LoginPage }
      />
      <Route
        path = { PATHNAME_SIGN_UP }
        component = { RegisterPage }
      />
    </Switch>
  </Router>

}

export default App