import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import Styled from 'styled-components'
import { v4 } from 'uuid'
import { Formik, Form as FormikForm, Field as FormikField } from 'formik'
import * as Yup from 'yup'

import { FONT_DEFAULT, PATHNAME_SIGN_UP } from '../../constants'
import { BLUE, RED, WHITE, YELLOW } from '../../theme'

type Props = {}


const Form = Styled (FormikForm) `
  padding: 2.5rem 1rem 0 2rem;
  max-width: 640px;
  width: 100%;
  margin: 0 auto;
  max-width: 470px;
`

const SignUpHeading = Styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: ${ FONT_DEFAULT };
  gap: 1rem;

  @media (min-width: 580px) {
    align-items center;
    flex-direction: row-reverse !important;
    justify-content: flex-start !important;
    flex-wrap: wrap;
  }
`

const SignUp = Styled (Link) `
  border: 1px solid ${ BLUE };
  padding: clamp(.3rem, 2vw, .4rem) clamp(1rem, 2vw, 2rem);
  font-size: clamp(.7rem, 2vw, .8rem);
  border-radius: 8px;
  transition: .25s ease-in-out;
  font-weight: 600;
  color: ${ BLUE };
  &:hover {
    border-color: ${ RED };
    background: ${ RED };
    color: ${ WHITE };
  }
`

const NoAccount = Styled.p `
  font-size: clamp(.7rem, 2vw, .8rem);
  color: #888;
  letter-spacing: 1px;
`

const WelcomeHeading = Styled.div `
  margin-top: 7rem;
`

const Welcome = Styled.h1 `
  display: flex;
  gap: .5rem;
  font-family: ${ FONT_DEFAULT };
  font-weight: 500;
  font-size: clamp(1rem, 2.7vw, 1.7rem);
`

const WelcomeText = Styled.span `
  font-family: inherit;
`

const WelcomeTextA = Styled (WelcomeText) `
  color: ${ YELLOW };
`
const WelcomeTextB = Styled (WelcomeText) `
  color: ${ BLUE };
`
const WelcomeTextC = Styled (WelcomeText) `
  color: ${ RED };
`

const Notice = Styled.p `
  color: #888;
  font-size: clamp(.85rem, 2vw, 1rem);
  font-family: ${ FONT_DEFAULT };
`

const ControlContainer = Styled.div `
  margin: 5rem 1rem 2rem auto;
`

const ControlWrapper = Styled.div `
  margin-bottom: 3rem;
  position: relative;
`

const Field = Styled (FormikField) `
  padding: clamp(.5rem, 2vw, .7rem) clamp(.7rem, 2vw, 1rem);
  width: 100%;
  font-family: ${ FONT_DEFAULT };
  font-size: clamp(.75rem, 2vw, .85rem);
  margin-top: 5px;
  border-radius: 8px;
  border: 1px solid #888;
`

const Label = Styled.label `
  font-size: clamp(.75rem, 2vw, .85rem);
  font-family: ${ FONT_DEFAULT };
`

const Submit = Styled.button `
  display: block;
  width: 100%;
  padding: clamp(.7rem, 2vw, 1rem);
  font-family: ${ FONT_DEFAULT };
  background: ${ BLUE };
  border: none;
  border-radius: 8px;
  color: ${ WHITE };
  cursor: pointer;
`

const Footer = Styled.div `
  display: flex;
  justify-content: center;
  gap: 2rem;
  align-items: center;
  margin-right: 1rem;
`

const FooterLink = Styled (Link) `
  font-size: clamp(.7rem, 2vw, .8rem);
  font-family: ${ FONT_DEFAULT };
  color: #888;
`

const ForgotPassword = Styled (FooterLink) ``
const PrivacyPolicy = Styled (FooterLink) ``

const Error = Styled.span `
  padding: .3rem 1rem;
  font-size: clamp(.5rem, 2vw, .75rem);
  position: absolute;
  bottom: -30px;
  right: 0;
  color: #fff;
  &::before {
    content: '';
    background: rgba(139,17,17,.7);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 20px;
  }
`

const LoginForm: FC <Props> = (props) => {

  const username_id: string = v4 ()
  const password_id: string = v4 ()

  const validation = Yup.object ().shape ({
    username: Yup.string ()
      .min (4, 'Too short')
      .required ('Enter your username'),
    password: Yup.string ()
      .min (8, 'Too short')
      .required ('Enter your password')
  })

  return <Formik
      onSubmit = {
        (values) => {}
      }
      validationSchema = { validation }
      initialValues = {{
        username: '',
        password: ''
      }}>
    {
      ({ errors, touched }: any) => 
      <Form>
        <SignUpHeading>
          <SignUp to = { PATHNAME_SIGN_UP }>Sign Up</SignUp>
          <NoAccount>Doesn't have an account?</NoAccount>
        </SignUpHeading>
        <WelcomeHeading>
          <Welcome>
            <WelcomeTextA>Welcome to</WelcomeTextA>
            <WelcomeTextB>Kabayan</WelcomeTextB>
            <WelcomeTextC>Doctors</WelcomeTextC>
          </Welcome>
          <Notice>Please login</Notice>
        </WelcomeHeading>
        <ControlContainer>
          <ControlWrapper>
            <Label htmlFor = { username_id }>
              Username
              <Field name = 'username' placeholder = 'Your username' />
            </Label>
            {
              errors.username && touched.username && 
              <Error>{ errors.username }</Error>
            }
          </ControlWrapper>
          <ControlWrapper>
            <Label htmlFor = { password_id }>
              Password
              <Field name = 'password' type = 'password' placeholder = 'Password' />
            </Label>
            {
              errors.password && touched.password && 
              <Error>{ errors.password }</Error>
            }
          </ControlWrapper>
          <Submit type = 'submit'>Sign In</Submit>
        </ControlContainer>
        <Footer>
          <ForgotPassword to = '/'>Forgot Password?</ForgotPassword>
          <PrivacyPolicy to = '/'>Privacy Policy</PrivacyPolicy>
        </Footer>
      </Form>
    }
  </Formik>

}

export default LoginForm