import React from 'react'
import ReactDOM from 'react-dom'
import App from './app'
import { HelmetProvider, Helmet } from 'react-helmet-async'

import FAVICON from './assets/favicon.svg'
import './index.css'

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet
        link = {[
          {
            rel: 'preconnect',
            href: 'https://fonts.googleapis.com',
            crossOrigin: ''
          },
          {
            rel: 'stylesheet',
            href: 'https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap'
          },
          {
            type: 'image/svg+xml',
            href: FAVICON,
            rel: 'icon'
          },
          {
            type: 'image/svg+xml',
            href: FAVICON,
            rel: 'apple-touch-icon'
          }
        ]}
      />
      <App />
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById ('root')
)
