import React, { FC, useState, MouseEvent } from 'react'
import Styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { BsChevronCompactDown } from 'react-icons/bs'

import { HeadNavigationProps } from '../props'
import { FONT_DEFAULT } from '../constants'
import { BLACK, BLUE, WHITE } from '../theme'

type Props = {
  app_name: string
} & HeadNavigationProps

const Container = Styled.header `
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3rem;
  max-width: 1200px;
  margin: 0 auto;
`

const Image = Styled.img `
  width: 96px;
`

const MenuList = Styled.ul <{ is_active: boolean }> `
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 0;
  display: flex;
  gap: 30px 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .75);
  transition: .5s ease-in-out;
  z-index: 998;
  overflow: hidden;

  ${
    props =>
    props.is_active && `
      height: 100%;
    `
  }

  @media (min-width: 768px) {
    position: initial;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: transparent;
    transition: .5s ease-in-out;
    height: 60px;
    z-index: unset;
    overflow: visible;
  }
`

const MenuItem = Styled.li <{ is_active: boolean, has_sub_menu: boolean }> `
  position: relative;
  a,
  button {
    color: ${ props => props.is_active ? BLUE : WHITE };
  }
  &:last-of-type {
    background: ${ BLUE };
    a,
    button {
      color: ${ WHITE };
      padding: 10px 20px;
      background: inherit;
      border-radius: 8px;
    }
  }
  ${
    props =>
    props.has_sub_menu && `
      display: flex;
      flex-direction: column;
      align-items: center;
    `
  }
  @media (min-width: 768px) {
    display: initial !important;
    a,
    button {
      color: ${ props => props.is_active ? BLUE : BLACK };
    }
  }
`

const MenuLink = Styled (Link) `
  font-family: ${ FONT_DEFAULT };
  font-size: .8rem;
  color: ${ BLACK };
`

const MenuButton = Styled.button `
  background: transparent;
  font-family: ${ FONT_DEFAULT };
  font-size: .8rem;
  font-weight: 500;
  color: ${ BLACK };
  border: none;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  @media (min-width: 768px) {
    transform: translateY(2px);
  }
`

const MenuToggler = Styled.button `
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 50px;
  background: transparent;
  border: none;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  &:active {
    transform: scaleX(.9);
  }

  @media (min-width: 768px) {
    display: none !important;
  }
`

const Hamburger = Styled.span <{ is_active: boolean }> `
  width: 80%;
  height: 3px;
  background: ${ BLACK };
  border-radius: 3px;
  position: relative;
  transition: .35s ease .15s;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: inherit;
    background: inherit;
    border-radius: inherit;
    transition: inherit;
  }

  &::before {
    transform: translate(0, -7px);
  }

  &::after {
    transform: translate(0, 7px);
  }

  ${
    props =>
    props.is_active && `
      transform: translate(100px, 0);

      &::before {
        transform: translate(-100px, 0) rotate(45deg);
        background: ${ WHITE };
      }
    
      &::after {
        transform: translate(-100px, 0) rotate(-45deg);
        background: ${ WHITE };
      }
    `
  }
`

const SubMenuList = Styled.ul <{ is_active: boolean }> `
  text-align: center;
  padding: 1rem 2rem 0 2rem;
  font-size: clamp(.75rem, 2vw, .8rem);

  @media (min-width: 768px) {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 5;
    transform: translate(0, 110%) scale(0);
    transform-origin: 0 0;
    text-align: left;
    background: #fff;
    border: 1px solid #888;
    width: 230px;
    padding: 0;
    overflow: hidden;
    transition: .35s ease-in-out;
    ${
      props =>
      props.is_active && `
        transform: translate(0, 110%) scale(1);
      `
    }
  }
`

const SubMenuItem = Styled.li `
  &:not(:last-of-type) {
    margin-bottom: 1.2rem;
  }
  @media (min-width: 768px) {
    margin-bottom: 0 !important;
    display: block;
    a {
      padding: 1rem;
      &:hover  {
        background: ${ BLUE };
        color: ${ WHITE };
      }
    }
  }
`

const SubMenuLink = Styled (Link) `
  font-family: ${ FONT_DEFAULT };
  display: inherit;
  color: ${ BLACK };
`

const HeadNavigation: FC <Props> = (props) => {

  const location = useLocation ()

  const [active_nav_item, setActiveNavItem] = useState <string> (
    props.menus.filter (
      ({ pathname, sub_menus }) => pathname === location.pathname)[0].pathname || '/'
  )
  const [is_active_toggle, setActiveToggle] = useState <boolean> (false)
  const [is_active_sub_menu, setActiveSubMenu] = useState <boolean> (false)

  return <Container>
    <Link to = { props.logo.link }>
      <Image src = { props.logo.image } alt = { props.app_name } />
    </Link>
    <MenuToggler
      onClick = {
        (e: MouseEvent <HTMLButtonElement>) =>
          setActiveToggle ((prev) => !prev)
      }
      type = 'button'>
      <Hamburger is_active = { is_active_toggle } />
    </MenuToggler>
    <MenuList is_active = { is_active_toggle }>
      { 
        props.menus.map (
          (({ text, pathname, state, search, sub_menus }, i: number) =>
            <MenuItem key = { i } is_active = { pathname === active_nav_item } has_sub_menu = { sub_menus && sub_menus.length ? true : false }>
              {
                sub_menus && sub_menus.length ?
                <MenuButton
                  type = 'button'
                  onClick = {
                    (e: MouseEvent <HTMLButtonElement>) =>
                      setActiveSubMenu ((prev) => !prev)
                  }
                >
                  { text }
                  <BsChevronCompactDown />
                </MenuButton> :
                <MenuLink
                  className = { active_nav_item === pathname ? 'active' : '' }
                  onClick = {
                    (e: MouseEvent <HTMLAnchorElement>) => {
                      setActiveNavItem (pathname)
                      setActiveToggle (false)
                      setActiveSubMenu (false)
                    }
                  }
                  to = {{ search, state, pathname }}>
                  { text }
                </MenuLink>
              }
              {
                sub_menus && sub_menus.length && <SubMenuList is_active = { is_active_sub_menu }>
                  {
                    sub_menus.map (
                      ({ text, pathname, state, search }, i: number) =>
                        <SubMenuItem key = { i }>
                          <SubMenuLink
                            onClick = {
                              (e: MouseEvent <HTMLAnchorElement>) => {
                                setActiveNavItem (pathname)
                                setActiveToggle (false)
                                setActiveSubMenu (false)
                              }
                            }
                            to = {{ search, state, pathname }}>{ text }</SubMenuLink>
                        </SubMenuItem>
                    )
                  }
                </SubMenuList>
              }
            </MenuItem>
          )
        )
      }
    </MenuList>
  </Container>

}

export default HeadNavigation