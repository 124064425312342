import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import Styled from 'styled-components'

import { LinkItemProps } from '../../props'
import { FONT_DEFAULT } from '../../constants'
import { RED, BLUE, WHITE } from '../../theme'

type Props = {
  buttons: Array <LinkItemProps>
}

const Section = Styled.section `
  margin-bottom: 3rem;
`

const Container = Styled.div `
  background: ${ BLUE };
  color: ${ WHITE };
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const Heading = Styled.div `
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const Title = Styled.h3 `
  font-size: clamp(.5rem, 2vw, .81rem);
  font-family: ${ FONT_DEFAULT };
  font-weight: 500;
`

const ButtonGroup = Styled.div `
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 2rem;
  justify-content: center;
  padding: 0 2rem;
`

const Button = Styled (Link) `
  background: transparent;
  border: 1px solid ${ WHITE };
  border-radius: 5px;
  font-family: ${ FONT_DEFAULT };
  padding: 15px 20px;
  font-size: clamp(.6rem, 2vw, .8rem);
  color: ${ WHITE };
  transition: .25s ease-in-out;
  &:hover {
    color: ${ WHITE };
    background: ${ RED };
    border-color: transparent;
  }
`

const HomePageQuickLinksSection: FC <Props> = (props) => {
  return <Section>
    <Container>
      <Heading>
        <Title>Quick Medical Links</Title>
      </Heading>
      <ButtonGroup>
        {
          props.buttons.map (
            ({ text, pathname, search, state }, i: number) =>
              <Button
                rel = 'noreferrer noopener'
                target = '_blank'
                to = {{
                pathname,
                search,
                state
                }}
                key = { i }>{ text }</Button>
          )
        }
      </ButtonGroup>
    </Container>
  </Section>
}

export default HomePageQuickLinksSection
