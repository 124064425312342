import { LinkItemProps, ListItemProps } from '../../props'
import { PATHNAME_SIGN_UP } from '../../constants'
import Radiologist from '../../../assets/home/radiologist.svg'
import Oncologist from '../../../assets/home/oncologist.svg'
import InfectiousDisease from '../../../assets/home/infectious.svg'
import GeneralMedicine from '../../../assets/home/general_medicine.svg'
import Cardiologist from '../../../assets/home/cardio.svg'
import DoctorImage from '../../../assets/home/doctor.png'
import Telemedicine from '../../../assets/home/telemedicine.svg'
import Scheduling from '../../../assets/home/scheduling.svg'
import ElectronicMedicalRecords from '../../../assets/home/emr.svg'
import { BLACK, BLUEGREEN, ORANGE, YELLOWGREEN } from '../../theme'
import { descriptions as ABOUT_DESCRIPTIONS } from '../about_us/data'

export const LANDING_QUICK_LINKS: Array <LinkItemProps> = [
  {
    pathname: PATHNAME_SIGN_UP,
    text: 'Emergency Medical Services',
  },
  {
    pathname: PATHNAME_SIGN_UP,
    text: 'Hospital Sign-up Numbers'
  },
  {
    pathname: 'https://kdoctorsschedweb.pythonph.com/',
    text: 'Schedule Doctors Visit'
  }
]

export const LANDING_BUTTONS: Array <LinkItemProps> = [
  {
    pathname: PATHNAME_SIGN_UP,
    text: 'Learn more',
  },
  {
    pathname: PATHNAME_SIGN_UP,
    text: 'Sign up for free'
  }
]

export const ABOUT_US_LIST_ITEMS: Array <ListItemProps> = [
  {
    description: 'Diagnose and treat certain conditions using a wide variety of imaging techniques, like x-rays, CT scans, and MRI\'s',
    title: 'Radiologist',
    image: {
      src: Radiologist
    }
  },
  {
    description: 'Treats cancer and provides medical care for a person diagnosed with cancer.',
    title: 'Oncologist',
    image: {
      src: Oncologist
    }
  },
  {
    description: 'Treats acute and chronic infections caused by bacteria, parasites, fungi and viruses, including COVID-19',
    title: 'Infectious Disease',
    image: {
      src: InfectiousDisease
    }
  },
  {
    description: 'Provide care for patients of all ages. This includes treatment for adults, adolescents, and children that does not involve surgery.',
    title: 'General Medicine',
    image: {
      src: GeneralMedicine
    }
  },
  {
    description: 'Treat problems of the heart and blood vessels.',
    title: 'Cardiologist',
    image: {
      src: Cardiologist
    }
  }
]

export const ABOUT_US_DOCTOR_IMAGE_SRC: string = DoctorImage
export const ABOUT_US_DESCRIPTION_LIST_ITEMS: Array <string> = [
  ABOUT_DESCRIPTIONS[0],
  ABOUT_DESCRIPTIONS[1],
  'From a global perspectives, In many of the countries in which we will be positioned, there are minimal amounts of specialists - Radiology, General Medicine, Oncology and many more.'
]

export const SERVICES_LIST_ITEMS: Array <ListItemProps> = [
  {
    description: 'Allows healthcare professionals to evaluate diagnose and treat patients at a distance using our application.',
    title: 'Telemedicine',
    color: {
      base: ORANGE,
      text: BLACK
    },
    image: {
      src: Telemedicine
    },
    link: {
      pathname: 'http://kdoctorsweb.pythonph.com/'
    }
  },
  {
    description: 'Makes it easy for healthcare professionals and patients to manage appointments.',
    title: 'Scheduling',
    color: {
      base: BLUEGREEN,
      text: BLACK
    },
    image: {
      src: Scheduling
    },
    link: {
      pathname: 'http://kdoctorsschedweb.pythonph.com/'
    }
  },
  {
    description: 'Manage patients records anytime anywhere.',
    title: 'Electronic Medical Records',
    color: {
      base: YELLOWGREEN,
      text: BLACK
    },
    image: {
      src: ElectronicMedicalRecords
    },
    link: {
      pathname: 'http://kdoctorsemrweb.pythonph.com/'
    },
    className: 'emr'
  }
]
