import React, { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import Styled from 'styled-components'
import { APP_NAME } from '../../constants'

import SignWrapper from '../sign_wrapper'
import LoginForm from './form'

type Props = {}

const Container = Styled.main `
  max-width: 1920px;
  margin: 0 auto;
`

const LoginPage: FC <Props> = (props) => {

  useEffect (() => {
    window.scrollTo(0, 0)
  }, [])

  return <Container>
    <Helmet
      title = { APP_NAME.concat (' - Sign In') }
    />
    <SignWrapper>
      <LoginForm />
    </SignWrapper>
  </Container>

}

export default LoginPage