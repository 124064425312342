import React, { FC } from 'react'
import Styled from 'styled-components'
import { Helmet } from 'react-helmet-async'

import FooterNavigation from '../../component/FooterNavigation'
import MainContainer from '../main_container'
import { BLUE, RED, YELLOW } from '../../theme'
import { APP_NAME, FONT_DEFAULT } from '../../constants'
import BackgroundImage from '../../../assets/about_us/bg.png'
import { descriptions } from './data'

type Props = {}

const Heading = Styled.div `
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  &::before {
    background: ${ YELLOW };
    transform: translateY(50%);
    top: 50%;
    left: 0;
    right: 0;
    height: 5px;
    content: '';
    position: absolute;
    z-index: -1;
  }
`

const Title = Styled.h1 `
  font-size: clamp(1.6rem, 3vw, 2.3rem);
  display: inline-flex;
  gap: 10px;
  background: #fff;
  transform: translate(50px, 5px);
  padding: 0 1rem;
  position: relative;
  z-index: 3;
  font-family: ${ FONT_DEFAULT };
`

const Text = Styled.span <{ data_text: string }> `
  position: relative;
  &:nth-of-type(1) { color: ${ RED }; }
  &:nth-of-type(2) { color: ${ BLUE }; }
  font-size: inherit;
  line-height: 25px;
  &::before {
    content: '${ props => props.data_text }';
    position: absolute;
    left: 50%;
    opacity: .5;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    direction: rtl;
    unicode-bidi: bidi-override;
    transform: translate(-50%, 70%) rotate(-180deg) scaleY(.6);

    @media (min-width: 874px) {
      transform: translate(-50%, 80%) rotate(-180deg) scaleY(.6);
    }
    @media (min-width: 1050px) {
      transform: translate(-50%, 100%) rotate(-180deg) scaleY(.6);
    }
  }
`

const Wrapper = Styled.div `
  position: relative;
  max-width: 1070px;
  margin: 5px auto 0 auto;
  z-index: 2;
`

const Image = Styled.img `
  max-width: 640px;
  width: 100%;
`

const List = Styled.ul `
  position: relative;
  padding: 2rem;
  font-family: ${ FONT_DEFAULT };
  max-width: 840px;
  transform: translate(0, -4.6px);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.6);
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 55, 165, .75);
    border-radius: inherit;
  }

  @media (min-width: 720px) {
    transform: translate(0, -60%);
    margin: 0 1rem;
    border-radius: 10px;
  }

  @media (min-width: 992px) {
    transform: translate(10%, -60%);
  }
`

const ListItem = Styled.li `
  padding: 1rem;
  position: relative;
`

const ListText = Styled.p `
  color: #fff;
  font-size: clamp(.7rem, 2vw, .9rem);
  font-weight: 300;
  letter-spacing: .5px;
`

const AboutUsPage: FC <Props> = (props) => {

  return <MainContainer>
    <Helmet
      title = { APP_NAME.concat (' - About Us') }
    />
    <Heading>
      <Title>
        <Text data_text = 'About'>About</Text>
        <Text data_text = 'Us'>Us</Text>
      </Title>
    </Heading>
    <Wrapper>
      <Image
        src = { BackgroundImage }
        alt = 'about-us'
      />
      <List>
        {
          descriptions.map (
            (item: string, i: number) =>
              <ListItem key = { i }>
                <ListText>{ item }</ListText>
              </ListItem>
          )
        }
      </List>
    </Wrapper>
    <FooterNavigation />
  </MainContainer>

}

export default AboutUsPage