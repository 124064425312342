import React, { FC } from 'react'
import Styled from 'styled-components'
import { Helmet } from 'react-helmet-async'

import ContactUsPageForm from './form'
import FooterNavigation from '../../component/FooterNavigation'
import MainContainer from '../main_container'
import ContactUsPageAddress from './address'
import { BLACK, BLUE, RED, YELLOW } from '../../theme'
import { APP_NAME, FONT_DEFAULT } from '../../constants'

type Props = {}

const Wrapper = Styled.div `
  display: grid;
  max-width: 1080px;
  margin: 0 auto 3rem auto;
  border-radius: 10px;
  overflow: hidden;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 800px) {
    grid-template-columns: 1fr .7fr;
  }
`

const Heading = Styled.div `
  position: relative;
  &::before {
    background: ${ YELLOW };
    transform: translateY(50%);
    top: 50%;
    left: 0;
    right: 0;
    height: 5px;
    content: '';
    position: absolute;
    z-index: -1;
  }
`

const Title = Styled.h1 `
  font-size: clamp(1.6rem, 3vw, 2.3rem);
  display: inline-flex;
  gap: 10px;
  background: #fff;
  transform: translate(50px, 5px);
  padding: 0 1rem;
  font-family: ${ FONT_DEFAULT };
`

const Text = Styled.span `
  &:nth-of-type(1) { color: ${ RED }; }
  &:nth-of-type(2) { color: ${ BLUE }; }
  &:nth-of-type(3) { color: ${ YELLOW }; }
  font-size: inherit;
`

const Subtitle = Styled.p `
  font-size: clamp(.8rem, 2vw, .9rem);
  margin: 1rem 0 2rem 4rem;
  color: ${ BLACK };
  font-family: ${ FONT_DEFAULT };
  max-width: 380px;
  letter-spacing: 1px;
`

const ContactUsPage: FC <Props> = (props) => {

  return <MainContainer>
    <Helmet
      title = { APP_NAME.concat (' - Contact Us') }
    />
    <Heading>
      <Title>
        <Text>Connect</Text>
        <Text>With</Text>
        <Text>Us</Text>
      </Title>
    </Heading>
    <Subtitle>We would love to respond to your queries and help you succeed. Feel free to get in touch with us.</Subtitle>
    <Wrapper>
      <ContactUsPageForm />
      <ContactUsPageAddress />
    </Wrapper>
    <FooterNavigation />
  </MainContainer>

}

export default ContactUsPage