import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import Styled from 'styled-components'

import { ListItemProps } from '../../props'
import { FONT_DEFAULT, PATHNAME_SIGN_UP } from '../../constants'
import { RED, YELLOW, BLUE, BLACK, BLUEGREEN, WHITE } from '../../theme'

type Props = {
  items: Array <ListItemProps>
  descriptions: Array <string>
  doctor_image_src: string
}

const Section = Styled.section `
  margin-bottom: 3rem;
`

const HeadingContainer = Styled.div `
  display: grid;
  grid-template-columns: 1fr auto auto auto .2fr 1fr;
  grid-template-areas:
    '. h_primary h_primary h_primary h_line1 h_line1'
    'h_line2 h_line2 h_secondary_a h_secondary_a h_secondary_b .';
  margin: 3rem auto 4rem auto;
  gap: 0 10px;
`

const HeadingText = Styled.h3 `
  font-family: ${ FONT_DEFAULT };
  font-weight: 600;
  font-size: clamp(1rem, 2vw, 2rem);
`

const HeadingTextPrimary = Styled (HeadingText) `
  color: ${ RED };
  grid-area: h_primary;
`

const HeadingTextSecondaryA = Styled (HeadingText) `
  grid-area: h_secondary_a;
  color: ${ BLUE };
`

const HeadingTextSecondaryB = Styled (HeadingText) `
  grid-area: h_secondary_b;
  margin-left: .5vw;
  color: ${ YELLOW };
`

const Line = Styled.span `
  height: 2px;
  width: 100%;
  margin: auto;
  background: ${ YELLOW };
`

const HeadingLine1 = Styled (Line) ` grid-area: h_line1; `
const HeadingLine2 = Styled (Line) ` grid-area: h_line2; `

const List = Styled.ul `
  max-width: 1024px;
  margin: 0 auto 3rem auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;

  @media (min-width: 768px) {
    gap: 5rem 3rem;
  }
`

const ListItem = Styled.li `
  max-width: 180px;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: ${ FONT_DEFAULT };
  gap: 1rem;

  @media (min-width: 620px) { max-width: 280px; }
  @media (min-width: 720px) { gap: 1.5rem; }
`

const ListImageContainer = Styled.div `
  max-width: 48px;
  position: relative;
  border-radius: 50%;
  transition: transform .4s ease;

  &::before,
  &::after {
    content: '';
    border-radius: inherit;
    position: absolute;
    border: 1px solid;
    transition: transform .4s ease,
                opacity .4s linear;
    opacity: 0;
    transform: scale(.9)
  }

  &::before {
    border-color: ${ YELLOW };
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px;
  }

  &::after {
    border-color: ${ RED };
    top: -7px;
    bottom: -7px;
    left: -7px;
    right: -7px;
  }

  &:hover {
    transform: scale(.8);
    &::before,
    &::after {
      transform: scale(1);
      opacity: 1;    l
    }
  }

  @media (min-width: 480px) { max-width: 56px; }
  @media (min-width: 720px) { max-width: 64px; }
`

const ListImage = Styled.img `
  display: block;
  width: 100%;
  height: auto;
`

const ListTitle = Styled.h3 `
  text-transform: uppercase;
  color: ${ BLUE };
  font-weight: 700;
  font-size: clamp(1rem, 2vw, 1.3rem);
`

const ListDescription = Styled.p `
  line-height: 1.8;
  text-align: center;
  font-size: clamp(.85rem, 1.5vw, .95rem);
  color: ${ BLACK };
` 

const Grid = Styled.div <{ paragraph_count: number }> `
  display: grid;
  gap: 1rem 3rem;
  grid-template-areas:
    'kb_wrapper'
  ;
  padding: 1.5rem;

  @media (min-width: 768px) {
    grid-template-rows: 1fr minmax(322px, 340px);
    grid-template-areas:
      'd_img .'
      'd_img kb_wrapper'
      'd_img .'
    ;
    grid-template-columns: .3fr 1fr;
  }
`

const DoctorImageContainer = Styled.div `
  display: none;
  grid-area: d_img;
  position: relative;
  &::before {
    z-index: -1;
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 90%;
    background: ${ BLUE };
    border-radius: 10px;
    box-shadow: 6px 8px 10px pink;
  }

  @media (min-width: 768px) { display: initial; }
`

const DoctorImage = Styled.img `
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  transform: translate(25%, 1px);
  min-width: 230px;
`

const AboutDescriptionList = Styled.ul `
  margin: auto 0 2.5rem 0;
`

const AboutDescriptionListItem = Styled.li `
  font-family: ${ FONT_DEFAULT };
  font-size: clamp(.85rem, 1.5vw, .95rem);
  color: ${ BLACK };
  &:not(:last-of-type) {
    margin-bottom: 1.5rem;
  }
`

const AboutWrapper = Styled.div `
  grid-area: kb_wrapper;
  margin: auto;
`

const AboutHeading = Styled.div `
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 1.5rem;
`

const AboutHeadingTitle = Styled.h3 `
  font-family: ${ FONT_DEFAULT };
  font-weight: 500;
  text-transform: uppercase;
  font-size: clamp(1rem, 2vw, 1.5rem);
`
const AboutHeadingTitleA = Styled (AboutHeadingTitle) `
  color: ${ RED };
`
const AboutHeadingTitleB = Styled (AboutHeadingTitle) `
  color: ${ BLUE };
`

const AboutButton = Styled (Link) `
  font-size: clamp(.7rem, 2vw, .75rem);
  font-family: ${ FONT_DEFAULT };
  border: 1px solid ${ BLUEGREEN };
  padding: .5rem 2rem;
  border-radius: 5px;
  color: ${ BLUE };
  transition: .35s ease;

  &:hover {
    background: ${ RED };
    border-color: transparent;
    color: ${ WHITE };
  }
`

const HomePageAboutUsSection: FC <Props> = (props) => {

  return <Section>
    <HeadingContainer>
      <HeadingLine1 />
      <HeadingLine2 />
      <HeadingTextPrimary>Consult With Us</HeadingTextPrimary>
      <HeadingTextSecondaryA>Anytime,</HeadingTextSecondaryA>
      <HeadingTextSecondaryB>Anywhere</HeadingTextSecondaryB>
    </HeadingContainer>
    <List>
      {
        props.items.map (
          ({ image, title, description }: ListItemProps, i: number) =>
            <ListItem key = { i }>
              {
                image && 
                <ListImageContainer>
                  <ListImage { ...image } alt = { image.alt || title } />
                </ListImageContainer>
              }
              <ListTitle>{ title }</ListTitle>
              <ListDescription>{ description }</ListDescription>
            </ListItem>
        )
      }
    </List>
    <Grid paragraph_count = { props.descriptions.length }>
      <DoctorImageContainer>
        <DoctorImage
          src = { props.doctor_image_src }
          alt = 'doctor'
        />
      </DoctorImageContainer>
      <AboutWrapper>
        <AboutDescriptionList>
          <AboutHeading>
            <AboutHeadingTitleA>Kabayan</AboutHeadingTitleA>
            <AboutHeadingTitleB>Doctors</AboutHeadingTitleB>
          </AboutHeading>
          {
            props.descriptions.map (
              (item: string, i: number) =>
                <AboutDescriptionListItem key = { i }>{ item }</AboutDescriptionListItem>
            )
          }
        </AboutDescriptionList>
        <AboutButton to = { PATHNAME_SIGN_UP }>Learn More</AboutButton>
      </AboutWrapper>
    </Grid>
  </Section>

}

export default HomePageAboutUsSection