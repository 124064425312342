import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import Styled from 'styled-components'

import LogoSVG from '../../assets/logo.svg'
import BackgroundImage from '../../assets/sign_in/bg.jpg'
import { APP_NAME } from '../constants'
import { BLUE, RED, YELLOW } from '../theme'

type Props = {}

const Section = Styled.section `
  @media (min-width: 640px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-template-rows: 100vh auto;
  }
  @media (min-width: 1200px) {
    grid-template-columns: 40% 60%;
  }
`

const Image = Styled.img `
  display: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
  @media (min-width: 640px) {
    display: initial;
  }
`

const Anchor = Styled (Link) `
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  transform: translate(-25%, -25%);
  background: rgba(255,255,255,.6);
  border-radius: 50%;
  aspect-ratio: 1;
  display: flex;
  max-width: 160px;
  max-height: 150px;
  width: 100%;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    max-width: 360px;
    max-height: 330px;
  }
`

const Border = Styled.span `
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 3px solid ${ BLUE };
  border-radius: inherit;
  &::before,
  &::after {
    position: absolute;
    content: '';
    border: inherit;
    border-radius: inherit;
  }
  &::before {
    top: -10px;
    left: -10px;
    bottom: -10px;
    right: -10px;
    border-color: ${ YELLOW };
  }
  &::after {
    top: -17px;
    left: -17px;
    bottom: -17px;
    right: -17px;
    border-color: ${ RED };
  }

  @media (min-width: 768px) {
    border-width: 5px;
    &::before {
      top: -15px;
      left: -15px;
      right: -15px;
      bottom: -15px;
    }
    &::after {
      top: -25px;
      left: -25px;
      right: -25px;
      bottom: -25px;
    }
  }
`

const Logo = Styled.img `
  width: 100%;
  max-width: 72px;
  height: auto;
  transform: translate(25%, 25%);

  @media (min-width: 768px) {
    max-width: 140px;
  }
`

const SignWrapper: FC <Props> = (props) => {

  return <Section>
    <Image src = { BackgroundImage } alt = { `Sign in with ${ APP_NAME }` } />
    <Anchor to = '/'>
      <Logo src = { LogoSVG } alt = { APP_NAME } />
      <Border />
    </Anchor>
    { props.children }
  </Section>

}

export default SignWrapper