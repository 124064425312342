import React, { FC, useEffect } from 'react'
import Styled from 'styled-components'
import { NAVIGATION_INITIAL_HEIGHT } from '../constants'

type Props = {}

const Container = Styled.main `
  min-height: calc(100vh - ${ NAVIGATION_INITIAL_HEIGHT });
  max-width: 1200px;
  margin: 0 auto;
`

const MainContainer: FC <Props> = (props) => {


  useEffect (() => {
    window.scrollTo(0, 0)
  }, [])

  return <Container>
    { props.children }
  </Container>

}

export default MainContainer